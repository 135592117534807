@import '../box.scss';

.health {
  @extend %box;
  text-align: center;

  .checkbox:not(:last-child) {
    margin-right: 8px;
  }

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
}
