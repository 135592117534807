@import 'src/styles/_focus-styles.scss';
@import 'src/styles/_reset-button.scss';
@import 'src/styles/_variables.scss';

$button-border-color-default: #000;
$button-background-color-default: #fff;
$button-background-color-default-hover: #f5f5f5;
$button-background-color-danger: $danger;
$button-background-color-success: $success;

$button-inner-border-box-shadow: inset 0px 0px 0px 1px white;

.btn {
  @extend %reset-button;
  position: relative;
  padding: 5px 17px;
  border: 2px solid $button-border-color-default;
  box-shadow: $button-inner-border-box-shadow;
  transition: all 0.2s;
  background-color: $button-background-color-default;
  color: $button-border-color-default;

  &:disabled {
    border-color: #808080;
    color: #808080;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: $button-background-color-default-hover;
    transform: translate(-1px, -1px);
    box-shadow: $button-inner-border-box-shadow, 2px 2px 1px $button-background-color-default-hover;
  }

  &:active {
    transform: translate(0, 0);
  }

  &:focus {
    outline: none;
    @include focus-styles();
  }

  &.danger {
    background-color: $button-background-color-danger;
    border-color: $button-background-color-danger;
    color: #fff;

    &:disabled {
      background-color: $danger-light;
      border-color: $danger-light;
    }

    &:hover:not(:disabled) {
      background-color: $button-background-color-danger;
    }
  }

  &.success {
    background-color: $button-background-color-success;
    border-color: $button-background-color-success;
    color: #fff;

    &:disabled {
      background-color: $success-light;
      border-color: $success-light;
    }

    &:hover:not(:disabled) {
      background-color: $button-background-color-success;
    }
  }

  &.link {
    border: none;
    padding: 0;
    background: none;
    text-decoration: underline;
    color: $link-color;
    cursor: pointer;

    &:disabled {
      color: $link-color-disabled;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      transform: none;
      background-color: none;
      color: $link-color-hover;
    }
  }

  &.icon {
    display: inline-flex;
    padding: 0;
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    border: 1px solid $button-border-color-default;
    background-color: transparent;

    & .inner {
      display: inline-block;
    }
  }
}

.innerIcon {
  display: inline-block;
  margin-right: 6px;
  transform: translate(0, 1px);
}
