@import 'styles/_variables.scss';
@import 'styles/_media-queries.scss';

.container {
  margin-left: 16px;
  margin-right: 16px;

  @include media-md {
    margin-left: 32px;
    margin-right: 32px;
  }

  @include media-lg {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
