.oneLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.form {
  width: 100%;
}

.value {
  font-family: 'Courier New', Courier, monospace;
}
