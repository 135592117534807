@import 'src/styles/_variables.scss';

@import './box.scss';

.characterView {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  gap: 6px 12px;
  width: 628px;
  margin: 0 auto;
}

.description {
  grid-area: 1 / 1 / 2 / 12;
}

.left {
  grid-area: 2 / 1 / 3 / 4;
}

.right {
  grid-area: 2 / 4 / 3 / 12;
}

.contacts {
  grid-area: 3 / 1 / 4 / 12;
}

.notes {
  grid-area: 4 / 1 / 5 / 12;
}
