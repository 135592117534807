@import 'styles/_variables.scss';

@import '../box.scss';

.description {
  @extend %box;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    width: calc(50% - 6px);
  }
}
