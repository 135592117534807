@use 'sass:math';

@import 'styles/_variables.scss';

.header {
  margin-bottom: math.div($vertical-rhythm, 2);
  border-bottom: 1px solid lightslategray;
}

.tableRow {
  margin-bottom: math.div($vertical-rhythm, 2);
  padding: math.div($vertical-rhythm, 2) 0;
  border-bottom: 1px solid lightgray;
}

.iconInButton {
  margin-left: 8px;
}
