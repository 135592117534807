@import 'src/styles/_variables.scss';

@import '../box.scss';

.attributes {
  @extend %box;

  & > *:not(:last-child) {
    margin-bottom: $vertical-rhythm;
  }
}
